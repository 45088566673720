<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<ng-container *ngIf="!!token?.authenticated">
    <app-topbar></app-topbar>
    <div class="main-content">
        <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>

</ng-container>
<ng-container *ngIf="!token?.authenticated">
    <app-login></app-login>
</ng-container>
