<header class="topbar">
    <div class="brand">
        <img class="pointer logo" src="assets/images/login-bg.png" alt="Logo" />
    </div>
    <div class="topbar-left">
        <ul class="nav-links" [hidden]="connectedUser?.isTempPassword">
            <li><a routerLinkActive="active" routerLink="/home" class="pointer">{{ 'nav.home' | translate }}</a></li>
            <li [hasRole]="['ADMIN', 'DIRECTOR']"><a routerLinkActive="active" routerLink="/directory/shared" class="pointer">{{ 'nav.shared' | translate }}</a></li>
            <li><a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" routerLink="/directory" class="pointer">{{ 'nav.directories' | translate }}</a></li>
            <li [hasRole]="['ADMIN', 'DIRECTOR']"><a routerLinkActive="active" routerLink="/category" class="pointer">{{ 'nav.categories' | translate }}</a></li>
        </ul>
    </div>
    <div class="topbar-right">
        <ul class="admin-links" [hasRole]="['ADMIN']" [hidden]="connectedUser?.isTempPassword">
            <li><a routerLinkActive="active" routerLink="/users" class="pointer">{{ 'nav.users' | translate }}</a></li>
            <li><a routerLinkActive="active" routerLink="/organigramme" class="pointer">{{ 'nav.organization-chart' | translate }}</a></li>
        </ul>
        <div class="language-selector">
            <i (click)="toggleLanguageMenu()" class="fas fa-globe pointer"></i>
            <div class="language-dropdown" *ngIf="showLanguageMenu">
                <ul class="flex-column">
                    <li (click)="selectLanguage('ar')">{{'lang.ar' | translate}}</li>
                    <li (click)="selectLanguage('fr')">{{'lang.fr' | translate}}</li>
                    <li (click)="selectLanguage('en')">{{'lang.en' | translate}}</li>
                </ul>
            </div>
        </div>
        <div class="user-profile pointer">
            <i (click)="toggleUserProfile()" class="fas fa-user-circle"></i>
            <div class="user-dropdown" *ngIf="showUserProfile">
                <ul class="flex-column">
<!--                    <li routerLink="/profile" (click)="showUserProfile = !showUserProfile"><a class="pointer">{{ connectedUser?.username }}</a></li>-->
                    <li><span (click)="logout()" class="pointer">{{ 'global.logout' | translate }}</span></li>
                </ul>
            </div>
        </div>
    </div>
</header>
