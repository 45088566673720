<footer class="footer">
    <div class="container">
        <div class="footer-content">
            <div class="footer-left">
                <p>{{'footer.title' | translate: {year: currentYear} }}</p>
            </div>
            <div class="footer-right">
                <ul class="footer-links">
                    <li><a href="#">{{'footer.privacy' | translate}}</a></li>
                    <li><a href="#">{{'footer.terms' | translate}}</a></li>
                    <li><a href="#">{{'footer.contact' | translate}}</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>
