<div class="wrapper">
  <div class="header d-inline-flex justify-content-between">
    <h4 class="modal-title">{{ title }}</h4>
    <button (click)="dismiss()" aria-label="Close" class="close" type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ message }}
  </div>
  <div class="modal-footer">
    <button (click)="decline()" class="btn btn-cancel" type="button">{{ btnCancelText }}</button>
    <button (click)="accept()" class="btn btn-submit" type="button">{{ btnOkText }}</button>
  </div>
</div>
