<div class="wrapper">
    <div class="row d-flex justify-content-between">
            <div class="login-box">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
                    <div class="form-group">
                        <label for="password">{{'login.username' | translate}}</label>
                        <input type="text" id="username" class="form-control" formControlName="username">
                    </div>
                    <div class="form-group">
                        <label for="password">{{'login.password' | translate}}</label>
                        <input type="password" id="password" class="form-control" formControlName="password">
                    </div>
                    <button type="submit" class="btn btn-primary btn-block" [disabled]="!loginForm.valid">{{'login.login' | translate}}</button>
                </form>
            </div>
    </div>
</div>
