import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {RoleEnum} from "../models/enumeration/role-enum";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
                private authenticationService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = this.authenticationService.tokenValue;
        const path = route?.routeConfig?.path;
        const role = token?.user?.permissions[0];
        if (token && this.canAccess(role, path)) {
            return true;
        }
        this.authenticationService.logout();
        return false;
    }

    private canAccess(role, path){
        switch (path){
            case 'users': return role == RoleEnum.ADMIN;
            case 'organigramme': return role == RoleEnum.ADMIN;
        }
        return true;
    }

}
