export const navigationItems: any = [
    {
        label: 'dashboard.title',
        icon: 'fas fa-tachometer-alt',
        routerLink: '/dashboard',
        routerLinkActiveOptions: {exact: true}
    },
    {
        label: 'employee.title',
        icon: 'fas fa-user-tie',
        routerLink: '/employee',
        routerLinkActiveOptions: {exact: true}
    },
    {
        label: 'paid.title',
        icon: 'fas fa-money-check',
        routerLink: '/paid',
        routerLinkActiveOptions: {exact: true}
    },
    {
        label: 'leave.title',
        icon: 'fas fa-users-slash',
        routerLink: '/leave',
        routerLinkActiveOptions: {exact: true}
    },
    {
        label: 'loan.title',
        icon: 'fas fa-receipt',
        routerLink: '/loan',
        routerLinkActiveOptions: {exact: true}
    },
    {
        label: 'holiday.title',
        icon: 'fas fa-calendar-times',
        routerLink: '/holiday',
        routerLinkActiveOptions: {exact: true}
    },
    {
        label: 'position.title',
        icon: 'fas fa-align-right',
        routerLink: '/position/list',
        routerLinkActiveOptions: {exact: true},
    },
    {
        label: 'category.title',
        icon: 'fas fa-align-right',
        routerLink: '/position/category',
        routerLinkActiveOptions: {exact: true},
    },
    // {
    //     label: 'salary.title',
    //     icon: 'fas fa-money-bill-alt',
    //     routerLink: '/salary',
    //     routerLinkActiveOptions: {exact: true}
    // },
    {
        label: 'compensation.title',
        icon: 'fas fa-comment-dollar',
        routerLink: '/compensation',
        routerLinkActiveOptions: {exact: true}
    },
    {
        label: 'bank.title',
        icon: 'fas fa-comment-dollar',
        routerLink: '/bank',
        routerLinkActiveOptions: {exact: true}
    },
    {
        label: 'employee-attendance.title',
        icon: 'fas fa-calendar-alt',
        routerLink: '/attendance',
        routerLinkActiveOptions: {exact: true}
    }
];
