import {Component} from '@angular/core';
import {navigationItems} from "../../../shared/navigation";
import {AuthenticationService} from "../../../services/authentication.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  menuItems = navigationItems;

  constructor(
    private authService: AuthenticationService
  ) {
  }

  logout() {
    this.authService.logout();
  }
}
