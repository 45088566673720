<div class="custom-pagination">
    <button
            class="btn btn-sm btn-primary"
            (click)="goToFirstPage()"
            [disabled]="currentPage === 0">
        <i class="fas fa-angle-double-left"></i> <!-- First Page Icon -->
    </button>

    <button
            class="btn btn-sm btn-primary"
            (click)="goToPreviousPage()"
            [disabled]="currentPage === 0">
        <i class="fas fa-angle-left"></i> <!-- Previous Page Icon -->
    </button>

    <span class="page-info">
    {{'global.page' | translate}} {{currentPage + 1}} {{'global.of' | translate}} {{totalPages}}
  </span>

    <button
            class="btn btn-sm btn-primary"
            (click)="goToNextPage()"
            [disabled]="currentPage >= totalPages - 1">
        <i class="fas fa-angle-right"></i> <!-- Next Page Icon -->
    </button>

    <button
            class="btn btn-sm btn-primary"
            (click)="goToLastPage()"
            [disabled]="currentPage >= totalPages - 1">
        <i class="fas fa-angle-double-right"></i> <!-- Last Page Icon -->
    </button>
</div>
