<div class="row d-flex justify-content-start align-content-center h-100">
        <ng-container *ngFor="let button of buttons">
                <i class="menu-icon ml-1"
                   (click)="(!button.disabled ||
                     (button.disabled && !button.disabled(data))) && button.fn(data);"
                   *ngIf="!!button.icon"
                   [class.disabled-icon]="button.disabled && button.disabled(data)"
                   [class.hide-icon]="button.hidden && button.hidden(data)"
                   [ngClass]="button.icon"
                   [ngStyle]="button.style ? button.style : {}"
                   [title]="button.title"></i>
        </ng-container>
</div>
