<aside class="sidebar">
  <ul class="nav-links">
    <br>
    <li *ngFor="let item of menuItems" routerLinkActive="active" style="margin: 5px">
      <a routerLink="{{item?.routerLink}}">
        <i class="{{item?.icon}}"></i>
        {{item.label | translate}}
      </a>
    </li>
  </ul>
  <span (click)="logout()" class="logout-container">
      <i class="fas fa-sign-out-alt"></i>
    {{'global.logout' | translate}}
    </span>
</aside>
