import {Component, EventEmitter, Input, Output} from '@angular/core';
import {GridApi, GridOptions} from 'ag-grid-community';

@Component({
    selector: 'app-ag-pagination',
    templateUrl: './ag-pagination.component.html',
    styleUrls: ['./ag-pagination.component.scss']
})
export class AgPaginationComponent {
    @Input() gridApi: GridApi; // Reference to the grid options
    @Input() gridOptions: GridOptions; // Reference to the grid options
    @Input() totalRows; // Reference to the grid options
    currentPage: number = 0;           // Current page index (0-based)
    totalPages: number = 0;            // Total number of pages

    @Output() pageChanged = new EventEmitter<number>();

    ngOnInit(): void {
        this.updatePagination();
    }

    ngOnChanges(): void {
        this.updatePagination();
    }

    updatePagination(): void {
        const pageSize = this.gridOptions?.paginationPageSize || 20;
        if (!this.totalRows) {
            this.totalRows = this.gridApi?.getDisplayedRowCount();
        }
        this.totalPages = Math.ceil(this.totalRows / pageSize);
    }

    goToFirstPage(): void {
        this.currentPage = 0;
        this.pageChanged.emit(this.currentPage);
    }

    goToPreviousPage(): void {
        if (this.currentPage > 0) {
            this.currentPage--;
            this.pageChanged.emit(this.currentPage);
        }
    }

    goToNextPage(): void {
        if (this.currentPage < this.totalPages - 1) {
            this.currentPage++;
            this.pageChanged.emit(this.currentPage);
        }
    }

    goToLastPage(): void {
        this.currentPage = this.totalPages - 1;
        this.pageChanged.emit(this.currentPage);
    }

}
