import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {DomLayoutType, FilterChangedEvent, GridApi, GridOptions, SortChangedEvent} from "ag-grid-community";
import {AG_GRID_LOCALE_FR} from "../ag-grid-i18n/locale.fr";
import {AgGridActionsButtonsComponent} from "../ag-grid-actions-buttons/ag-grid-actions-buttons.component";

@Component({
    selector: 'app-ag-grid-wrapper',
    templateUrl: './ag-grid-wrapper.component.html',
    styleUrls: ['./ag-grid-wrapper.component.scss']
})
export class AgGridWrapperComponent implements OnInit, OnChanges {
    @Input()
    columns: any[];
    @Input()
    rowData: any[];
    @Input()
    domLayout = 'autoHeight' as DomLayoutType;
    overlayLoadingTemplate;

    @Input() pageSize = 10;
    @Input() pagination = true;

    @Output() onFilter = new EventEmitter<any>();
    @Output() onSort = new EventEmitter<any>();


    frameworkComponents: any;
    gridOptions: GridOptions;
    gridApi: GridApi;
    localeText: any;

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
    }

    ngOnInit(): void {
        this.agGridColumnConfig();
        this.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Chargement en cours ...</span>';
    }


    gridReady(params) {
        this.gridApi = params.api;
    }

    agGridColumnConfig() {
        this.frameworkComponents = {
            buttonRenderer: AgGridActionsButtonsComponent,
        };
        this.localeText = this.initAgGridLocale();
        this.gridOptions = <GridOptions>{
            rowHeight: 45,
            pagination: this.pagination,
            paginationPageSize: this.pageSize,
            paginationPageSizeSelector: false,
            rowModelType: 'clientSide',
            enableColResize: true,
            enableSorting: false,
            enableFilter: true,
            defaultColDef: {
                sortable: true,
                filter: true,
                floatingFilter: false,
                filterParams: {
                    suppressAndOrCondition: true,
                    caseSensitive: false,
                    filterOptions: ['contains'],
                }
            },
            columnDefs: this.columns,
            deltaRowDataMode: true,
            getRowNodeId: (data) => {
                return data.id;
            },
        };
    }

    initAgGridLocale() {
        const AG_GRID_LOCAL = {};
        Object.keys(AG_GRID_LOCALE_FR).forEach(function (key) {
            AG_GRID_LOCAL[key] = AG_GRID_LOCALE_FR[key];
        });
        return AG_GRID_LOCAL;
    }

    onFilterChanged(event: FilterChangedEvent<any>) {
        const gridFilters = this.gridApi.getFilterModel()
        this.onFilter.emit(gridFilters);
    }


    onSortChanged(event: SortChangedEvent<any>) {
        const gridSortModel = this.gridOptions?.sortingOrder;
        this.onSort.emit(gridSortModel);
    }
}
