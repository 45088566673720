import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {InputOnlyNumberDirective} from "./input-only-number.directive";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {ReactiveFormsModule} from "@angular/forms";
import {AgGridModule} from 'ag-grid-angular';
import {AgGridWrapperComponent} from "./ag-grid-components/ag-grid-wrapper/ag-grid-wrapper.component";
import {
    AgGridActionsButtonsComponent
} from "./ag-grid-components/ag-grid-actions-buttons/ag-grid-actions-buttons.component";
import {FileSizePipe} from "./pipes/file-size.pipe";
import {SafeUrlPipe} from "./pipes/safe-url.pipe";
import {AgPaginationComponent} from "./ag-grid-components/ag-pagination/ag-pagination.component";


@NgModule({
    declarations: [
        InputOnlyNumberDirective,
        AgGridWrapperComponent,
        AgGridActionsButtonsComponent,
        FileSizePipe,
        SafeUrlPipe,
        AgPaginationComponent
    ],
    imports: [
        CommonModule,
        AgGridModule,
        TranslateModule,
        NgbModule,
        ReactiveFormsModule
    ],
    exports: [
        AgGridModule,
        TranslateModule,
        InputOnlyNumberDirective,
        NgbModule,
        ReactiveFormsModule,
        AgGridWrapperComponent,
        AgGridActionsButtonsComponent,
        FileSizePipe,
        SafeUrlPipe,
        AgPaginationComponent
    ]
})
export class SharedModule {
}
